@import '../utils/main.scss';

.our-business {
  display: flex;
  flex-direction: column;
   margin: auto;
    height: 600px;
    width: 100%;
   z-index: 2;
    background-color: $background_color_div;
    
    .container-our-business {
      display: flex;
      flex-direction: row;
      height: 100%;
      width: 100%;
      padding: 60px;
      align-items: center;
      justify-content: space-between;
    
  
    .row-image {
      flex: 1;
      height: 400px;
      width: 45%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover; 
      }
    }
  
    .row-text {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center; // Vertikalno centriranje
      padding: 20px;
      height: 400px;
      width: 45%;
  
      .title {
        margin-bottom: 20px;
        font-size: 1.5rem;
        h1{
            color: white;
        }
      }
  
      .description {
        margin-bottom: 20px;
  
        p {
          margin: 10px 0; 
          line-height: 1.5;
          color: white;
        }
      }
  
      span {
        color: rgb(168,23,63); // Crvena boja za span
      }
  
      .button-see-more {
        button {
          padding: 10px 20px;
          background-color: rgb(168,23,63); // Primjer boje gumba
          color: white;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          margin-top: 30px;
  
          &:hover {
            background-color: #0056b3; // Boja na hover
          }
          a{
            text-decoration: none;
            color: white;
            font-size: 1rem;
          }
        }
      }
    }}
  }
  // Small mobile (max-width: 480px)
@media (max-width: 480px) {
  .our-business{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    min-height:120vh ;
    z-index: 3;
    padding-bottom: 3rem;
    padding-top: 2rem;
    .container-our-business{
      display: flex;
      flex-direction: column;
      width: 100%;
     // padding: 0 1.2rem;
      .row-image{
        display: none;
      }
      .row-text{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: .5rem;
      // height: 100%;

        .title{
          h1{
            font-weight: 300;
            text-align: justify;
            span{
              text-align: justify;
            }
          }
        }
        .description{
          p{
         line-height: 2;
         text-align: justify;
        }}
        .button-see-more{
          button{
            margin-top: 0;
          }
        }
      }

    }
  }
}

  // Mobile
  @media (max-width: 768px) {
    .our-business{
      display: flex;
      flex-direction: column;
      width: 100%;
      min-height:70vh ;
      .container-our-business{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 1.2rem;
        .row-image{
          display: none;
        }
        .row-text{
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 70vh;
          justify-content: space-evenly;
          .title{
            h1{
              font-weight: 300;
              text-align: justify;
            }
          }
          .description{
            p{
              line-height: 2;
              text-align: justify;
            }
          }
        }
  
      }
    }
}

    // Tablet portrait
    
    @media (min-width: 769px) and (max-width: 991px) {
      .our-business{
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height:90vh ;
        justify-content: center;
        .container-our-business{
          display: flex;
          flex-direction: row;
          width: 100%;
          padding: 0 1.2rem;
          .row-image{
            display: flex;
            padding-right: 2rem;

            img{
              display: flex;
             height: 100%;
             clip-path: polygon(0 0, 100% 0, 100% 50%, 85% 100%, 0 100%);
             box-shadow: 3px 3px 5px rgba(255, 255, 255, 0.3), 6px 6px 15px rgba(185, 185, 185, 0.15);

                        }

          }
          .row-text{
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 70vh;
            justify-content: space-evenly;
            .title{
              margin-bottom: 0;
              h1{
                font-weight: 300;
                font-size: 2rem;
              }
            }
            .description{
              p{
                line-height: 1.5;
                font-weight: 300;
                margin:0;
                font-size: 1.15rem;
              }
            }
          }
    
        }
      }
    }
    
    // Tablet landscape, Laptop, Desktop
    
    @media (min-width: 992px) {}
    
    // Large Desktop, TV etc.
    
    @media (min-width: 1200px) {}