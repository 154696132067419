@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Edu+AU+VIC+WA+NT+Guides:wght@400..700&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap");
.our-business {
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 600px;
  width: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.9);
}
.our-business .container-our-business {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  padding: 60px;
  align-items: center;
  justify-content: space-between;
}
.our-business .container-our-business .row-image {
  flex: 1;
  height: 400px;
  width: 45%;
}
.our-business .container-our-business .row-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.our-business .container-our-business .row-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  height: 400px;
  width: 45%;
}
.our-business .container-our-business .row-text .title {
  margin-bottom: 20px;
  font-size: 1.5rem;
}
.our-business .container-our-business .row-text .title h1 {
  color: white;
}
.our-business .container-our-business .row-text .description {
  margin-bottom: 20px;
}
.our-business .container-our-business .row-text .description p {
  margin: 10px 0;
  line-height: 1.5;
  color: white;
}
.our-business .container-our-business .row-text span {
  color: rgb(168, 23, 63);
}
.our-business .container-our-business .row-text .button-see-more button {
  padding: 10px 20px;
  background-color: rgb(168, 23, 63);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 30px;
}
.our-business .container-our-business .row-text .button-see-more button:hover {
  background-color: #0056b3;
}
.our-business .container-our-business .row-text .button-see-more button a {
  text-decoration: none;
  color: white;
  font-size: 1rem;
}

@media (max-width: 480px) {
  .our-business {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    min-height: 120vh;
    z-index: 3;
    padding-bottom: 3rem;
    padding-top: 2rem;
  }
  .our-business .container-our-business {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .our-business .container-our-business .row-image {
    display: none;
  }
  .our-business .container-our-business .row-text {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0.5rem;
  }
  .our-business .container-our-business .row-text .title h1 {
    font-weight: 300;
    text-align: justify;
  }
  .our-business .container-our-business .row-text .title h1 span {
    text-align: justify;
  }
  .our-business .container-our-business .row-text .description p {
    line-height: 2;
    text-align: justify;
  }
  .our-business .container-our-business .row-text .button-see-more button {
    margin-top: 0;
  }
}
@media (max-width: 768px) {
  .our-business {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 70vh;
  }
  .our-business .container-our-business {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 1.2rem;
  }
  .our-business .container-our-business .row-image {
    display: none;
  }
  .our-business .container-our-business .row-text {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 70vh;
    justify-content: space-evenly;
  }
  .our-business .container-our-business .row-text .title h1 {
    font-weight: 300;
    text-align: justify;
  }
  .our-business .container-our-business .row-text .description p {
    line-height: 2;
    text-align: justify;
  }
}
@media (min-width: 769px) and (max-width: 991px) {
  .our-business {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 90vh;
    justify-content: center;
  }
  .our-business .container-our-business {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 1.2rem;
  }
  .our-business .container-our-business .row-image {
    display: flex;
    padding-right: 2rem;
  }
  .our-business .container-our-business .row-image img {
    display: flex;
    height: 100%;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 50%, 85% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% 50%, 85% 100%, 0 100%);
    box-shadow: 3px 3px 5px rgba(255, 255, 255, 0.3), 6px 6px 15px rgba(185, 185, 185, 0.15);
  }
  .our-business .container-our-business .row-text {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 70vh;
    justify-content: space-evenly;
  }
  .our-business .container-our-business .row-text .title {
    margin-bottom: 0;
  }
  .our-business .container-our-business .row-text .title h1 {
    font-weight: 300;
    font-size: 2rem;
  }
  .our-business .container-our-business .row-text .description p {
    line-height: 1.5;
    font-weight: 300;
    margin: 0;
    font-size: 1.15rem;
  }
}/*# sourceMappingURL=OurBusiness.css.map */