.bathroom-page {
  padding: 1rem;
  display: flex;
  justify-content: center;
  background: rgb(0,0,0);
  background: linear-gradient(178deg, rgba(0,0,0,1) 45%, rgba(66,66,66,1) 100%);
  .bath-main {
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
  }

  .gallery-grid {
    display: flex;
    flex-wrap: wrap;
    gap:2rem;
    justify-content: center;

    .gallery-item {
      flex: 1 1 calc(33.33% - 20px); // Za desktop prikaz tri slike u redu
      max-width: calc(33.33% - 20px);
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1rem;


      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;

      }
      a {
        display: block;
        width: 100%;
        height: 100%;
      }

      .gallery-image {
        width: 100%;
        height: auto;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }

  // Media queries za različite veličine ekrana
  @media (max-width: 480px) {
    .gallery-grid {
      .gallery-item {
        flex: 1 1 100%; // Jedna slika po redu na najmanjim ekranima
        max-width: 100%;
        margin: 0;
      }
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    .gallery-grid {
      .gallery-item {
        flex: 1 1 calc(50% - 20px); // Dvije slike u redu na manjim ekranima
        max-width: calc(50% - 20px);
      }
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .gallery-grid {
      .gallery-item {
        flex: 1 1 calc(33.33% - 20px); // Tri slike u redu na tabletima
        max-width: calc(33.33% - 20px);
      }
    }
  }

  @media (min-width: 992px) {
    .gallery-grid {
      .gallery-item {
        flex: 1 1 calc(25% - 20px); // Četiri slike u redu na velikim ekranima
        max-width: calc(25% - 20px);
      }
    }
  }
}
