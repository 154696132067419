@import "../utils/main.scss";

.gallery-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background: rgb(0,0,0);
  background: linear-gradient(178deg, rgba(0,0,0,1) 45%, rgba(66,66,66,1) 100%);
  main {
    display: flex;
    flex-direction: column;
  width: 100%;
    height: 100%;
    margin-top: 1rem;
    margin-bottom: 8rem;
   align-items: center;

    .main-container {
      display: flex;
      flex-direction: column;
      max-width: 1400px;
      height: 100%;
      align-content: center;
      

      .title-section {
        display: flex;
        flex-direction: column;
        padding: 4rem;

        p {
          font-size: 2rem;
          color: rgb(168,23,63);
          font-family: 'Caveat', sans-serif;
          text-align: center;
          font-weight: 600;
          letter-spacing: 2px;
          margin-top: 2rem;
          margin-bottom: 4rem;
        }
      }

      .article-about {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 600px;
        justify-content: space-between;
        margin-bottom: 8rem;

        &:nth-child(even) {
          flex-direction: row-reverse;

          img {
            clip-path: polygon(100% 0, 100% 0, 100% 100%, 0 68%, 0 15%);
          }
        }

        .article-about-box-descriptions {
          display: flex;
          flex-direction: column;
          width: 50%;
          padding: 3rem;

          h2 {
            color: white;
            font-size: 2.2rem;
            font-family: 'Roboto Mono', sans-serif;
            letter-spacing: 3px;
            font-weight: 200;
            margin-bottom: 5rem;
          }

          p {
            font-size: 1.3rem;
            color: white;
            letter-spacing: 2px;
            font-family: 'Roboto Mono';
            line-height: 1.5;
            font-weight: 300;
          }

          a {
            text-decoration: none;
            margin-top: 2rem;
            border: 1 solid rgb(168,23,63);
            border-radius: 0.5rem;
            padding: 0.5rem;
            color: white;
            background-color: rgb(168,23,63);
            width: 10rem;
            text-align: center;
          }
        }

        .article-about-box {
          display: flex;
          flex-direction: column;
          width: 50%;
          height: 100%;
          padding-left: 2rem;
          margin-bottom: 10rem;
          overflow: hidden;

          img {
            display: flex;
            width: 100%;
            height: 600px;
            background-attachment: fixed;
            object-fit: cover;
          //  clip-path: polygon(0 0, 70% 0, 100% 85%, 85% 100%, 0 100%);
          }
        }
      }
    }
  }
}

// Small mobile (max-width: 480px)
@media (max-width: 480px) {
  .gallery-page {
    main {
      margin-top: 3rem;
      margin-bottom: 4rem;

      .main-container {
        .title-section {
          padding: 2rem;

          p {
            font-size: 1.5rem;
            margin-top: 1rem;
            margin-bottom: 2rem;
          }
        }

        .article-about {
          flex-direction: column;
          height: auto;
          &:nth-child(even){
            flex-direction: row-reverse;
            position: relative;
            height: 100%;
          
          }
        
          .article-about-box-descriptions{
            p{
              letter-spacing: normal;
            }
          }


          .article-about-box-descriptions,
          .article-about-box {
           width: 100%;
            padding: 1rem;
            margin: 0;


        
            img {
             
              width: 100%;
              &:nth-child(even){
                display: flex;
                position: relative;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: fill;
              clip-path: none !important;
              }
            }

            h2 {
              font-size: 1.8rem;
              margin-bottom: 1.5rem;
            }

            p {
              font-size: 1rem;
            }
          }

        
        }
      }
    }
  }
}

// Middle mobile (481px - 767px)
@media (min-width: 481px) and (max-width: 767px) {
  .gallery-page {
    main {
      margin-top: 3rem;
      margin-bottom: 6rem;

      .main-container {
        .title-section {
          padding: 3rem;

          p {
            font-size: 1.8rem;
          }
        }

        .article-about {
          flex-direction: column;
          height: auto;
          &:nth-child(even){
            flex-direction: row-reverse;
            position: relative;
            height: 100%;
          }

          .article-about-box-descriptions,
          .article-about-box {
           width: 100%;
            padding: 1rem;
            margin: 0;


        
            img {
             
              width: 100%;
              &:nth-child(even){
                display: flex;
                position: relative;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              }
            }

            h2 {
              font-size: 1.8rem;
              margin-bottom: 1.5rem;
            }

            p {
              font-size: 1rem;
            }
          }

        
        }
      }
    }
  }
}

// Tablet (768px - 991px)
@media (min-width: 768px) and (max-width: 991px) {
  .gallery-page {
    main {
      margin-top: 1rem;
      margin-bottom: 7rem;

      .main-container {
        .title-section {
          padding: 3.5rem;

          p {
            font-size: 1.9rem;
          }
        }

        .article-about {
          .article-about-box-descriptions,
          .article-about-box {
            width: 100%;
            padding: 2.5rem;

            h2 {
              font-size: 2.1rem;
            }

            p {
              font-size: 1.2rem;
            }
          }

          .article-about-box img {
            height: 500px;
          }
        }
      }
    }
  }
}
