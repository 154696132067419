@import '../utils/main.scss';

.active {
  background-color: #cacaca83;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #00000017;
  color: white;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  height: 5rem;

  .logo-img {
    img {
      width: 6rem;
      margin: 1rem;
      font-weight: bold;
      text-decoration: none;
      color: white;
      clip-path: circle(70%);
    }
  }

  ul {
    display: flex;
    align-self: center;
    padding-right: 5%;

    li {
      list-style: none;
      padding-left: 1rem;

      a {
        font-size: 1.25rem;
        letter-spacing: 2px;
        font-weight: 200;
        display: block;
        text-decoration: none;
        color: white;
        padding: 0.5rem;
        margin: 0 0.5rem;
        border-radius: 0.5rem;
        font-family: 'Roboto Mono';
        &:not(.active):hover {
          background-color: #cacaca27;
        }
      }
    }
  }

  .menu {
    display: none; // Prikazujemo samo na manjim ekranima
    position: absolute;
    top: 0.75rem;
    right: 0.5rem;
    flex-direction: column;
    justify-content: space-between;
    width: 2.25rem;
    height: 2rem;
    cursor: pointer;

    svg {
      font-size: 2rem;
    }
  }
}

// Small mobile (max-width: 480px)
@media (max-width: 480px) {
  nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.950)!important;
    width: 100%;
    height: 4rem;

  

    .logo-img {
      display: flex;

      img {
        position: fixed;
        top: 0;
        left: 0;
        width: 3.5rem; // Smanji veličinu logotipa za small mobile
      }
    }

    .menu {
      display: flex; // Prikaži ikonu menija na malim ekranima
    }

    ul {
      display: none; // Sakrij li elemente dok se meni ne otvori
      flex-direction: column;
      width: 100%;
      margin-bottom: 0.25rem;

      &.open {
        display: flex; // Prikazivanje li elemenata kada je meni otvoren
        background-color: rgba(0, 0, 0, 0.699);
        padding: 0;
        margin-top: 4rem;

      }

      li {
        width: 100%;
        text-align: center;

        a {
          margin: 0.2rem 0.5rem;
          font-size: 1rem;
        }
      }
    }
  }
}

// Midle mobile (481px - 767px)
@media (min-width: 481px) and (max-width: 767px) {
  nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.950)!important;
    width: 100%;
    height: 4rem;

  

    .logo-img {
      display: flex;

      img {
        position: fixed;
        top: 0;
        left: 0;
        width: 3.5rem; // Smanji veličinu logotipa za small mobile
      }
    }

    .menu {
      display: flex; // Prikaži ikonu menija na malim ekranima
    }

    ul {
      display: none; // Sakrij li elemente dok se meni ne otvori
      flex-direction: column;
      width: 100%;
      margin-bottom: 0.25rem;

      &.open {
        display: flex; // Prikazivanje li elemenata kada je meni otvoren
        background-color: rgba(0, 0, 0, 0.699);
        padding: 0;
        margin-top: 4rem;

      }

      li {
        width: 100%;
        text-align: center;

        a {
          margin: 0.2rem 0.5rem;
          font-size: 1rem;
        }
      }
    }
  }
}


// Tablet (768px - 991px)
@media (min-width: 768px) and (max-width: 991px) {
  nav {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.950)!important;

    .logo-img {
      img {
        width: 6rem; // Održavaj veće dimenzije logotipa za tablet
      }
    }

    .menu {
      display: none; // Sakrij menu ikonu na većim uređajima
    }

    ul {
      display: flex;
       li{
        a{
          font-size: 1rem;
          margin: 0;
        }
       }
    }
  }
}

// Laptop/desktop (992px and up)
@media (min-width: 992px) {
  nav {
    ul {
      display: flex; // Prikaži li elemente
    }

    .menu {
      display: none; // Sakrij ikonu menija
    }
  }
}
