.home-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}
.home-page main {
  display: flex;
  flex-direction: column;
}
.home-page main section {
  display: block;
}

@media (max-width: 768px) {
  .home-page {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .home-page main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
}/*# sourceMappingURL=HomePage.css.map */