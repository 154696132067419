@import '../utils/main.scss';

.carousel-container {


  padding: 20px;
  text-align: center;
  background-color: $background_color_div;
  height: 100%;



}

.carousel-item {
  padding: 10px;
  background: #f8f8f8;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  height: 500px;
  margin-left: .3rem;
  margin-right: .3rem;
}

.carousel-image {
  width: 100%;
  height: 70%;
  object-fit: cover;
  border-radius: 10px;
}

.carousel-item h3 {
  margin-top: 1.5rem;
  font-size: 1.5rem;
  letter-spacing: 2px;
}

.carousel-item p {
  font-size: 1rem;
  color: #666;
  padding: 1rem;
}
.react-multi-carousel-dot-list {
  padding: 10px 0; /* Dodaje padding iznad i ispod cijelog popisa točaka */
  display: flex;
  justify-content: center;
}

// Small mobile (max-width: 480px)
@media (max-width: 480px) {
  .react-multi-carousel-dot-list {
    padding: 10px 0; /* Dodaje padding iznad i ispod cijelog popisa točaka */
    display: flex;
    justify-content: center;
  }

  }

  // Midle mobile (481px - 767px)
  
  @media (min-width: 481px) and (max-width: 767px) {
    .react-multi-carousel-dot-list {
    height: auto;
      padding-top: 4rem; /* Dodaje padding iznad i ispod cijelog popisa točaka */
      display: flex;
      justify-content: center;
    }
  }
  // Tablet (768px - 991px)
  
  @media (min-width: 768px) and (max-width: 991px) {
  }
