.services-page{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    background: url('../../assets/images/fullsize/pozadina_za_o_nama.webp');
    background-attachment: fixed;
    background-size: cover;

    main {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 100vh;
        background-color: rgba(0, 0, 0, 0.945);
        .services-description {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%; /* Pobrinimo se da ovaj element ima odgovarajuću visinu */
            align-items: center;

            .title {
                margin-top: 30px;
                margin-bottom: 50px;
                h2 {
                    font-size: 2.1rem;
                    font-weight: 200;
                    font-family: "roboto mono", sans-serif;
                    color: whitesmoke;

                    span {
                        color: rgb(168,23,63);
                        font-weight: 600;
                        text-wrap: wrap;
                    }
                }
            }

            .description-row {
                display: flex;
                flex-direction: row;
                justify-content: center;
                width: 80%;
                height: 100%;
                margin-top: 100px;
                justify-content: space-evenly;

                .left-side, .right-side {
                    display: flex;
                    flex-direction: column;
                    padding: 20px;
                    align-content: space-between;
                    
                    ul {
                        li {
                            line-height: 2.5;
                            color: whitesmoke;
                            letter-spacing: 2px;
                            font-weight: 400;

                            &:hover {
                                transform: scale(1.1);
                            }

                            a {
                                text-decoration: none;
                                color: rgb(168,23,63);
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
        }
    }
    
    /* Kontakt forma na manjim ekranima */
    .contact-form {
        clear: both; /* Pobrinite se da se formica prikazuje ispod svih prethodnih elemenata */
        margin-top: 2rem; /* Dodaj malo prostora */
    }
}

// Small mobile (max-width: 480px)
@media (max-width: 480px) {
    .services-page {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 100vh;

        main {
            display: flex;
            flex-direction: column;
            margin-top: 4rem;

            .services-description {
                min-height: 100vh;

                .title {
                    display: flex;
                    flex-direction: column;
                    margin-top: 1.5rem;
                    padding-left: 1rem;
                    margin-bottom: 1rem;

                    .title-motion {
                        h2 {
                            font-size: 1.8rem;
                        }
                    }
                }

                .description-row {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: auto;

                    .left-side, .right-side {
                        width: 100%;
                        height: auto;

                        ul {
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            align-items: self-start;
                            li{
                                padding-left: .7rem;
                            }
                        }
                    }
                }
            }
        }

        /* Ispravak za ContactForm */
        .contact-form {
            margin-top: 2rem;
        }
    }
}

// Midle mobile (481px - 767px)
@media (min-width: 481px) and (max-width: 767px) {
    .services-page {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 100vh;

        main {
            display: flex;
            flex-direction: column;
            margin-top: 4rem;

            .services-description {
                min-height: 100vh;

                .title {
                    display: flex;
                    flex-direction: column;
                    margin-top: 1.5rem;
                    padding-left: 1rem;
                    margin-bottom: 1rem;

                    .title-motion {
                        h2 {
                            font-size: 2rem;
                        }
                    }
                }

                .description-row {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: auto;

                    .left-side, .right-side {
                        width: 100%;
                        height: auto;

                        ul {
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                        }
                    }
                }
            }
        }

        /* ContactForm styling for middle mobile */
        .contact-form {
            margin-top: 2rem;
            padding: 1rem; /* Dodan padding za bolje prikazivanje */
        }
    }
}

// Tablet (768px - 991px)
@media (min-width: 768px) and (max-width: 991px) {
    .services-page {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 100vh;

        main {
            display: flex;
            flex-direction: column;
            margin-top: 5rem;

            .services-description {
                display: flex;
                flex-direction: column;
                width: 100%;
                align-items: center;

                .title {
                    display: flex;
                    flex-direction: column;
                    margin-top: 2rem;
                    margin-bottom: 1rem;
                    padding-left: 2rem;

                    .title-motion {
                        h2 {
                            font-size: 2.2rem;
                        }
                    }
                }

                .description-row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                    width: 90%;
                    margin-top: 2rem;

                    .left-side, .right-side {
                        width: 45%;
                        height: auto;
                        padding: 1rem;
                        
                        ul {
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                        }
                    }
                }
            }
        }

        /* ContactForm styling for tablets */
        .contact-form {
            margin-top: 2rem;
            padding: 1.5rem; /* Padding for tablet view */
        }
    }
}
