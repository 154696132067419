@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Edu+AU+VIC+WA+NT+Guides:wght@400..700&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap");
.kitchen {
  display: flex;
  flex-direction: column;
  padding-left: 3rem;
  padding-right: 3rem;
  width: 100%;
  min-height: 110vh;
  justify-content: center;
}
.kitchen:nth-child(even) {
  background-image: linear-gradient(120deg, rgba(253, 251, 251, 0.337254902) 0%, rgba(235, 237, 238, 0.5254901961) 100%);
}
.kitchen:nth-child(even) h2,
.kitchen:nth-child(even) p {
  color: rgb(255, 255, 255);
}
.kitchen .kitchen-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.kitchen .kitchen-box .title-kitchen {
  padding-bottom: 3rem;
  color: rgb(221, 218, 218);
}
.kitchen .kitchen-box .title-kitchen h2 {
  margin: 0;
  font-size: 2rem;
  padding-bottom: 2rem;
}
.kitchen .kitchen-box .title-kitchen p {
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: 400;
}
.kitchen .kitchen-box .title-kitchen p span {
  font-style: italic;
  font-weight: 200;
  font-family: "roboto mono", sans-serif;
}
.kitchen .kitchen-box .title-kitchen p a {
  text-decoration: none;
  color: rgb(221, 218, 218);
  padding-left: 1rem;
  font-style: italic;
  font-weight: 200;
  font-family: "roboto mono", sans-serif;
}
.kitchen .kitchen-box .title-kitchen p a:hover {
  text-decoration: underline;
}
.kitchen .kitchen-box .title-kitchen p:nth-child(4) {
  font-style: italic;
  font-size: 1.2rem;
}
.kitchen .kitchen-box .gallery {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
  align-content: space-between;
}
.kitchen .kitchen-box .gallery .large-image {
  display: flex;
  margin-right: 2rem;
  min-width: 500px;
  height: 600px;
  transition: transform 0.4s ease, opacity 0.4s ease;
}
.kitchen .kitchen-box .gallery .large-image:hover {
  transform: scale(1.05);
  opacity: 0.8;
}
.kitchen .kitchen-box .gallery .large-image a {
  display: flex;
  width: 100%;
  height: 100%;
}
.kitchen .kitchen-box .gallery .large-image img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  -o-object-fit: cover;
     object-fit: cover; /* Održava proporcije i popunjava kontejner */
  -o-object-position: center;
     object-position: center; /* Centriranje slike */
}
.kitchen .kitchen-box .gallery .small-images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 600px;
  justify-content: start;
  align-content: space-evenly;
  position: relative;
  transition: transform 0.4s ease, opacity 0.4s ease;
}
.kitchen .kitchen-box .gallery .small-images a {
  margin-left: 1rem;
  margin-bottom: 0.5rem;
  max-width: 120px;
  max-height: 120px;
}
.kitchen .kitchen-box .gallery .small-images a:hover {
  transform: scale(1.05);
  opacity: 0.8;
}
.kitchen .kitchen-box .gallery .small-images a img {
  display: flex;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 5px;
}
.kitchen .kitchen-box .arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
}
.kitchen .kitchen-box .arrow--left {
  left: 10px;
}
.kitchen .kitchen-box .arrow--right {
  right: 10px;
}
.kitchen .kitchen-box .arrow svg {
  fill: white;
  width: 20px;
  height: 20px;
}
.kitchen .pswp__img {
  display: block;
  width: 100%; /* Širina slike 100% od kontejnera */
  height: auto; /* Visina automatski postavljena prema proporcijama slike */
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center; /* Slika ispunjava prostor bez izobličenja */
  border-radius: 5px; /* Opcionalno, za zaobljene ivice */
}

@media (max-width: 480px) {
  .kitchen {
    padding-left: 1rem;
    padding-right: 1rem;
    height: auto;
    margin-top: 2rem;
  }
  .kitchen .kitchen-box .title-kitchen h2 {
    font-size: 1.5rem;
  }
  .kitchen .kitchen-box .title-kitchen p {
    font-size: 1rem;
    padding-top: 2rem;
  }
  .kitchen .kitchen-box .gallery {
    flex-direction: column;
  }
  .kitchen .kitchen-box .gallery .large-image {
    min-width: 100%;
    height: 300px;
  }
  .kitchen .kitchen-box .gallery .small-images a {
    max-width: 80px;
    max-height: 80px;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .kitchen {
    padding-left: 2rem;
    padding-right: 2rem;
    height: auto;
    margin-top: 2rem;
  }
  .kitchen .kitchen-box .title-kitchen h2 {
    font-size: 1.8rem;
  }
  .kitchen .kitchen-box .title-kitchen p {
    font-size: 1rem;
  }
  .kitchen .kitchen-box .gallery .large-image {
    min-width: 400px;
    height: 400px;
  }
  .kitchen .kitchen-box .gallery .small-images a {
    max-width: 100px;
    max-height: 100px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .kitchen {
    padding-left: 1rem;
    padding-right: 1rem;
    height: auto;
    margin-top: 4rem;
  }
  .kitchen .kitchen-box .title-kitchen {
    padding-top: 2rem;
  }
  .kitchen .kitchen-box .title-kitchen h2 {
    font-size: 2rem;
  }
  .kitchen .kitchen-box .title-kitchen p {
    font-size: 1rem;
  }
  .kitchen .kitchen-box .gallery .large-image {
    min-width: 350px;
    height: 450px;
  }
  .kitchen .kitchen-box .gallery .small-images a {
    max-width: 100px;
    max-height: 100px;
  }
}/*# sourceMappingURL=KitchenComponent.css.map */