@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Edu+AU+VIC+WA+NT+Guides:wght@400..700&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap");
.carousel-container {
  padding: 20px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.9);
  height: 100%;
}

.carousel-item {
  padding: 10px;
  background: #f8f8f8;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 500px;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

.carousel-image {
  width: 100%;
  height: 70%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
}

.carousel-item h3 {
  margin-top: 1.5rem;
  font-size: 1.5rem;
  letter-spacing: 2px;
}

.carousel-item p {
  font-size: 1rem;
  color: #666;
  padding: 1rem;
}

.react-multi-carousel-dot-list {
  padding: 10px 0; /* Dodaje padding iznad i ispod cijelog popisa točaka */
  display: flex;
  justify-content: center;
}

@media (max-width: 480px) {
  .react-multi-carousel-dot-list {
    padding: 10px 0; /* Dodaje padding iznad i ispod cijelog popisa točaka */
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .react-multi-carousel-dot-list {
    height: auto;
    padding-top: 4rem; /* Dodaje padding iznad i ispod cijelog popisa točaka */
    display: flex;
    justify-content: center;
  }
}/*# sourceMappingURL=OurProducts.css.map */