@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Edu+AU+VIC+WA+NT+Guides:wght@400..700&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap");
.scrolling-component {
  display: flex;
  flex-direction: column;
  height: 200px;
  width: 100%;
  justify-content: center;
  background-color: rgba(228, 223, 223, 0.9);
}
.scrolling-component .title-scroll-text {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}
.scrolling-component .title-scroll-text h2 {
  color: #333333;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 2px;
}
.scrolling-component .react-multi-carousel-list ul li p a {
  text-decoration: none;
  color: black;
  font-family: "roboto mono", sans-serif;
  font-size: 2rem;
  font-weight: 600;
  font-style: italic;
}
.scrolling-component .scrolling-text {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
}
.scrolling-component .scrolling-text .scroll-item {
  padding: 10px;
  font-size: 2rem;
  color: #333;
  margin-right: 50px;
}
.scrolling-component .scrolling-text .scroll-item a {
  text-decoration: none;
  color: #333;
}
.scrolling-component .scrolling-text .scroll-item a:hover {
  transform: scale(1.05);
}

@media (max-width: 580px) {
  .scrolling-component {
    display: flex;
    flex-direction: column;
    height: 100px;
    width: 100%;
    justify-content: center;
    background-color: rgba(228, 223, 223, 0.9);
  }
  .scrolling-component .title-scroll-text {
    display: flex;
    justify-content: center;
    margin-bottom: 0.5rem;
  }
  .scrolling-component .title-scroll-text h2 {
    color: #333333;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1.8px;
    font-size: 1.1rem;
    font-style: italic;
  }
  .scrolling-component .react-multi-carousel-list ul li p a {
    text-decoration: none;
    color: black;
    font-family: "roboto mono", sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    font-style: italic;
  }
  .scrolling-component .scrolling-text {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
  }
  .scrolling-component .scrolling-text .scroll-item {
    padding: 10px;
    font-size: 2rem;
    color: #333;
    margin-right: 50px;
  }
  .scrolling-component .scrolling-text .scroll-item a {
    text-decoration: none;
    color: #333;
  }
  .scrolling-component .scrolling-text .scroll-item a:hover {
    transform: scale(1.05);
  }
}/*# sourceMappingURL=ScrollingText.css.map */