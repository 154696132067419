@charset "UTF-8";
.services-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background: url("../../assets/images/fullsize/pozadina_za_o_nama.webp");
  background-attachment: fixed;
  background-size: cover;
  /* Kontakt forma na manjim ekranima */
}
.services-page main {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.945);
}
.services-page main .services-description {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%; /* Pobrinimo se da ovaj element ima odgovarajuću visinu */
  align-items: center;
}
.services-page main .services-description .title {
  margin-top: 30px;
  margin-bottom: 50px;
}
.services-page main .services-description .title h2 {
  font-size: 2.1rem;
  font-weight: 200;
  font-family: "roboto mono", sans-serif;
  color: whitesmoke;
}
.services-page main .services-description .title h2 span {
  color: rgb(168, 23, 63);
  font-weight: 600;
  text-wrap: wrap;
}
.services-page main .services-description .description-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80%;
  height: 100%;
  margin-top: 100px;
  justify-content: space-evenly;
}
.services-page main .services-description .description-row .left-side, .services-page main .services-description .description-row .right-side {
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-content: space-between;
}
.services-page main .services-description .description-row .left-side ul li, .services-page main .services-description .description-row .right-side ul li {
  line-height: 2.5;
  color: whitesmoke;
  letter-spacing: 2px;
  font-weight: 400;
}
.services-page main .services-description .description-row .left-side ul li:hover, .services-page main .services-description .description-row .right-side ul li:hover {
  transform: scale(1.1);
}
.services-page main .services-description .description-row .left-side ul li a, .services-page main .services-description .description-row .right-side ul li a {
  text-decoration: none;
  color: rgb(168, 23, 63);
  font-weight: 700;
}
.services-page .contact-form {
  clear: both; /* Pobrinite se da se formica prikazuje ispod svih prethodnih elemenata */
  margin-top: 2rem; /* Dodaj malo prostora */
}

@media (max-width: 480px) {
  .services-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    /* Ispravak za ContactForm */
  }
  .services-page main {
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
  }
  .services-page main .services-description {
    min-height: 100vh;
  }
  .services-page main .services-description .title {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    padding-left: 1rem;
    margin-bottom: 1rem;
  }
  .services-page main .services-description .title .title-motion h2 {
    font-size: 1.8rem;
  }
  .services-page main .services-description .description-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
  }
  .services-page main .services-description .description-row .left-side, .services-page main .services-description .description-row .right-side {
    width: 100%;
    height: auto;
  }
  .services-page main .services-description .description-row .left-side ul, .services-page main .services-description .description-row .right-side ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: self-start;
  }
  .services-page main .services-description .description-row .left-side ul li, .services-page main .services-description .description-row .right-side ul li {
    padding-left: 0.7rem;
  }
  .services-page .contact-form {
    margin-top: 2rem;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .services-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    /* ContactForm styling for middle mobile */
  }
  .services-page main {
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
  }
  .services-page main .services-description {
    min-height: 100vh;
  }
  .services-page main .services-description .title {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    padding-left: 1rem;
    margin-bottom: 1rem;
  }
  .services-page main .services-description .title .title-motion h2 {
    font-size: 2rem;
  }
  .services-page main .services-description .description-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
  }
  .services-page main .services-description .description-row .left-side, .services-page main .services-description .description-row .right-side {
    width: 100%;
    height: auto;
  }
  .services-page main .services-description .description-row .left-side ul, .services-page main .services-description .description-row .right-side ul {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .services-page .contact-form {
    margin-top: 2rem;
    padding: 1rem; /* Dodan padding za bolje prikazivanje */
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .services-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    /* ContactForm styling for tablets */
  }
  .services-page main {
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
  }
  .services-page main .services-description {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  .services-page main .services-description .title {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 1rem;
    padding-left: 2rem;
  }
  .services-page main .services-description .title .title-motion h2 {
    font-size: 2.2rem;
  }
  .services-page main .services-description .description-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 90%;
    margin-top: 2rem;
  }
  .services-page main .services-description .description-row .left-side, .services-page main .services-description .description-row .right-side {
    width: 45%;
    height: auto;
    padding: 1rem;
  }
  .services-page main .services-description .description-row .left-side ul, .services-page main .services-description .description-row .right-side ul {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .services-page .contact-form {
    margin-top: 2rem;
    padding: 1.5rem; /* Padding for tablet view */
  }
}/*# sourceMappingURL=servicesPage.css.map */