@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Edu+AU+VIC+WA+NT+Guides:wght@400..700&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap");
.privacy-Policy-Page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.938);
}
.privacy-Policy-Page .main-privacy-Policy-Page {
  display: flex;
  flex-direction: column;
  margin-top: 8rem;
  background-color: white;
  padding-bottom: 5rem;
  border-radius: 0 5rem 5rem 0;
}
.privacy-Policy-Page .main-privacy-Policy-Page .main-privacy-Policy-Page-title {
  display: flex;
  flex-direction: column;
  margin: 6rem 9rem 0 9rem;
}
.privacy-Policy-Page .main-privacy-Policy-Page .main-privacy-Policy-Page-title h2 {
  font-family: "roboto mono", sans-serif;
  font-size: 2rem;
  font-weight: 400;
  color: rgb(95, 95, 95);
  margin: 0.5rem 0 1rem;
}
.privacy-Policy-Page .main-privacy-Policy-Page .main-privacy-Policy-Page-title p {
  font-family: "roboto mono", sans-serif;
  font-size: 1.05rem;
  font-weight: 400;
  color: rgb(95, 95, 95);
  margin-bottom: 0.5rem;
}
.privacy-Policy-Page .main-privacy-Policy-Page .main-privacy-Policy-Page-title p a {
  font-family: "roboto mono", sans-serif;
  font-size: 1.05rem;
  font-weight: 400;
  color: rgb(95, 95, 95);
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
  text-decoration: none;
  color: #c36;
}
.privacy-Policy-Page .main-privacy-Policy-Page .main-privacy-Policy-Page-title p a:hover {
  color: rgb(95, 95, 95);
}
.privacy-Policy-Page .main-privacy-Policy-Page .main-privacy-Policy-Page-title ul {
  transform: translateX(2rem);
  margin-bottom: 0.3rem;
}
.privacy-Policy-Page .main-privacy-Policy-Page .main-privacy-Policy-Page-title ul li {
  font-family: "roboto mono", sans-serif;
  font-size: 1.05rem;
  font-weight: 400;
  color: rgb(95, 95, 95);
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
}
.privacy-Policy-Page .main-privacy-Policy-Page .main-privacy-Policy-Page-title ul li a {
  text-decoration: none;
  color: #c36;
}
.privacy-Policy-Page .main-privacy-Policy-Page .main-privacy-Policy-Page-title ul li a:hover {
  color: rgb(95, 95, 95);
}

@media (max-width: 480px) {
  .privacy-Policy-Page {
    display: flex;
    flex-direction: column;
  }
  .privacy-Policy-Page .main-privacy-Policy-Page {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 2rem;
    margin-top: auto;
    border-radius: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.486);
  }
  .privacy-Policy-Page .main-privacy-Policy-Page .main-privacy-Policy-Page-title {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    margin-top: 6rem;
  }
  .privacy-Policy-Page .main-privacy-Policy-Page .main-privacy-Policy-Page-title h2 {
    font-size: 1.5rem;
    color: white;
    margin-bottom: 3rem;
  }
  .privacy-Policy-Page .main-privacy-Policy-Page .main-privacy-Policy-Page-title p, .privacy-Policy-Page .main-privacy-Policy-Page .main-privacy-Policy-Page-title ul > li {
    font-size: 1rem;
    color: white;
    font-weight: 200;
    display: flex;
    flex-direction: column;
    text-wrap: wrap;
  }
  .privacy-Policy-Page .main-privacy-Policy-Page .main-privacy-Policy-Page-title address {
    color: white;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .privacy-Policy-Page {
    display: flex;
    flex-direction: column;
  }
  .privacy-Policy-Page .main-privacy-Policy-Page {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 2rem;
    margin-top: auto;
    border-radius: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.486);
  }
  .privacy-Policy-Page .main-privacy-Policy-Page .main-privacy-Policy-Page-title {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    margin-top: 6rem;
  }
  .privacy-Policy-Page .main-privacy-Policy-Page .main-privacy-Policy-Page-title h2 {
    font-size: 1.5rem;
    color: white;
    margin-bottom: 3rem;
  }
  .privacy-Policy-Page .main-privacy-Policy-Page .main-privacy-Policy-Page-title p, .privacy-Policy-Page .main-privacy-Policy-Page .main-privacy-Policy-Page-title ul > li {
    font-size: 1rem;
    color: white;
    font-weight: 200;
  }
  .privacy-Policy-Page .main-privacy-Policy-Page .main-privacy-Policy-Page-title address {
    color: white;
  }
}/*# sourceMappingURL=PrivacyPolicyPage.css.map */