@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Edu+AU+VIC+WA+NT+Guides:wght@400..700&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap");
.aboutUs-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background: url("../../assets/images/fullsize/pozadina_za_o_nama.webp");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}
.aboutUs-page main {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.95);
  min-height: 100vh;
  align-items: center;
}
.aboutUs-page main section {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin-top: 8rem;
  color: rgba(245, 245, 245, 0.671);
}
.aboutUs-page main section article {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
.aboutUs-page main section article .title-aboutus h2 {
  font-size: 2.5rem;
  color: red;
}
.aboutUs-page main section article .descriprion p {
  font-family: "roboto mono", sans-serif;
  margin-top: 2rem;
  letter-spacing: 2px;
  font-weight: 200;
  line-height: 1.8;
}
.aboutUs-page main section article .descriprion p span {
  color: red;
  margin-top: 4rem;
}

@media (max-width: 580px) {
  .aboutUs-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    background: url("../../assets/images/fullsize/pozadina_za_o_nama.webp");
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
  }
  .aboutUs-page main {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.95);
    min-height: 100vh;
    align-items: center;
  }
  .aboutUs-page main section {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin-top: 4rem;
    color: rgba(245, 245, 245, 0.671);
  }
  .aboutUs-page main section article {
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }
  .aboutUs-page main section article .title-aboutus h2 {
    font-size: 2.5rem;
    color: #a8173f;
  }
  .aboutUs-page main section article .descriprion p {
    font-family: "roboto mono", sans-serif;
    margin-top: 2rem;
    letter-spacing: 1px;
    font-weight: 200;
    line-height: 1.4;
  }
  .aboutUs-page main section article .descriprion p span {
    color: rgb(168, 23, 63);
    margin-top: 4rem;
  }
}/*# sourceMappingURL=aboutPage.css.map */