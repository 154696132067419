// src/pages/404.scss
.not-found-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background-image: url("../../assets//images/fullsize/404Page.webp"); // Replace with your image path
  background-size: cover;
  background-position: center;
  position: relative;
  .not-found {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.838);
    color: white;
    text-align: center;
    width: 100%;
min-height: 100vh;
    .not-found-content {
        display: flex;
        flex-direction: column;
      padding: 20px;
      border-radius: 10px;

   

      p {
        font-size: 3rem;
        font-family: "roborica", arial, helvetica;
        margin-bottom: 2rem;
      }

      a {
        font-size: 1.5rem;
        margin-top: 0.5rem;
        text-decoration: none;
        color: whitesmoke;
       &:hover {
          color: #f7f7f7;
         // text-decoration: double;
          transform: scale(1.1) ;
        }
      }
    }
  }
}
