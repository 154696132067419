.home-page{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    main{
        display: flex;
        flex-direction: column;
        section{
            display: block;
        }
    }
}
// Mobile size

@media (max-width: 768px) {
    .home-page {
        display: flex;
        flex-direction: column;
        width: 100%;
       // min-height: 100vh;
        main{
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
        }
    }
}

    // Tablet portrait
    
    @media (min-width: 768px) and (max-width: 991px) {}
    
    // Tablet landscape, Laptop, Desktop
    
    @media (min-width: 992px) {}
    
    // Large Desktop, TV etc.
    
    @media (min-width: 1200px) {}
    