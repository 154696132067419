@import "../utils/main.scss";

.header-component {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0;
  left: 0;
z-index: 1;
  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../../assets/images/fullsize/HomePage1.webp");
    background-size: cover;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.5); // Crni overlay
    filter: brightness(0.4); // Potamnjenje
    z-index: 1;
  }
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 2;
    p {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $text-color;
      margin-bottom: 1rem;

      span {
        font-size: 6rem;
        text-transform: uppercase;
        font-family: "Roboto Mono", monospace;
        font-optical-sizing: auto;
        letter-spacing: 20px;
        font-weight: 400;
        align-content: space-between;
      }
      :nth-child(2) {
        font-size: 5rem;
        font-weight: 400;
        margin-top: 1rem;
        margin-bottom: 60px;
      }
    }
    h2 {
      font-size: 1, 25rem;
      color: $text-color;
      text-transform: lowercase;
      font-weight: 300;
      letter-spacing: 4px;
      margin-top: 80px;
      transform: translate(-150px, 100px);
    }
  }
}

// Small mobile (max-width: 480px)
@media (max-width: 480px) {
  .header-component {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    .background-image {
      display: flex;
      width: 0100%;
    }
    .box {
      display: flex;
      width: 100%;
      p {
        width: 100%;
        &:nth-child(2){
          margin-top: 0;
          margin-bottom: 0;
        }
        span {
          i {
            font-size: 2rem;
            letter-spacing: 2px;
            &:nth-child(2){
              padding-top: 0;
              margin-top: 0;
            }
          }
        
        }
      }
      h2 {
      display: none;
      }
    }
  }
}

// Midle mobile (481px - 767px)
@media (min-width: 481px) and (max-width: 767px) {
  .header-component {
    display: flex;
    .box{
      display: flex;
      flex-direction: column;
      p{
        width: 100%;
        span {
          i {            
            @include fluid-font(2rem, 4rem);
            letter-spacing: .7rem;
          }
        }
      }
      h2{
        @include fluid-font(1rem, 1.5rem);
        transform: translate(0);
        padding-left: 1rem;
        font-weight: 200;
        letter-spacing: 1px;
      }
    }
  }
}
// Tablet portrait

@media (min-width: 768px) and (max-width: 991px) {
  .header-component {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    .background-image {
      display: flex;
      width: 100%;
    }
    .box {
      display: flex;
      width: 100%;
      p {
        width: 100%;

        span {
          i {
            font-size: 3rem;
          }
        }
      }
      h2 {
        font-size: 1.25rem;
        transform: none;
      }
    }
  }
}

// Tablet landscape, Laptop, Desktop

@media (min-width: 992px) {
}

// Large Desktop, TV etc.

@media (min-width: 1200px) {
}
