@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Edu+AU+VIC+WA+NT+Guides:wght@400..700&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap");
.header-component {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
}
.header-component .background-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../assets/images/fullsize/HomePage1.webp");
  background-size: cover;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.5);
  filter: brightness(0.4);
  z-index: 1;
}
.header-component .box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 2;
}
.header-component .box p {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgba(255, 255, 255, 0.781);
  margin-bottom: 1rem;
}
.header-component .box p span {
  font-size: 6rem;
  text-transform: uppercase;
  font-family: "Roboto Mono", monospace;
  font-optical-sizing: auto;
  letter-spacing: 20px;
  font-weight: 400;
  align-content: space-between;
}
.header-component .box p :nth-child(2) {
  font-size: 5rem;
  font-weight: 400;
  margin-top: 1rem;
  margin-bottom: 60px;
}
.header-component .box h2 {
  font-size: 1, 25rem;
  color: rgba(255, 255, 255, 0.781);
  text-transform: lowercase;
  font-weight: 300;
  letter-spacing: 4px;
  margin-top: 80px;
  transform: translate(-150px, 100px);
}

@media (max-width: 480px) {
  .header-component {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
  }
  .header-component .background-image {
    display: flex;
    width: 100%;
  }
  .header-component .box {
    display: flex;
    width: 100%;
  }
  .header-component .box p {
    width: 100%;
  }
  .header-component .box p:nth-child(2) {
    margin-top: 0;
    margin-bottom: 0;
  }
  .header-component .box p span i {
    font-size: 2rem;
    letter-spacing: 2px;
  }
  .header-component .box p span i:nth-child(2) {
    padding-top: 0;
    margin-top: 0;
  }
  .header-component .box h2 {
    display: none;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .header-component {
    display: flex;
  }
  .header-component .box {
    display: flex;
    flex-direction: column;
  }
  .header-component .box p {
    width: 100%;
  }
  .header-component .box p span i {
    font-size: clamp(2rem, 1rem + 1vw, 4rem);
    letter-spacing: 0.7rem;
  }
  .header-component .box h2 {
    font-size: clamp(1rem, 1rem + 1vw, 1.5rem);
    transform: translate(0);
    padding-left: 1rem;
    font-weight: 200;
    letter-spacing: 1px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .header-component {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
  }
  .header-component .background-image {
    display: flex;
    width: 100%;
  }
  .header-component .box {
    display: flex;
    width: 100%;
  }
  .header-component .box p {
    width: 100%;
  }
  .header-component .box p span i {
    font-size: 3rem;
  }
  .header-component .box h2 {
    font-size: 1.25rem;
    transform: none;
  }
}/*# sourceMappingURL=Header.css.map */