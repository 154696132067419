@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: 'Roboto', 'Open Sans', sans-serif;
  background-color: #f0f0f0a4;
  margin: 0;
  padding: 0;
}

.container {
  margin: 0 auto;
  width: 100%;
  position: relative; /* Promijenio iz absolute u relative */
 background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Ako želiš centriranje vertikalno */
  align-items: center; /* Ako želiš centriranje horizontalno */
}

@media (max-width: 1024px) {
  .container {
    background-attachment: scroll; /* Povećava performanse na mobilnim uređajima */
  }
}

.pswp__img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
}
