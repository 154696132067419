@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Edu+AU+VIC+WA+NT+Guides:wght@400..700&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap");
.faq-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
  background-color: rgb(0, 0, 0);
}
.faq-component .faq-component-title h2 {
  font-size: 2.5rem;
  margin-bottom: 60px;
  color: #ffffff;
  font-family: "roboto mono", sans-serif;
  font-weight: 400;
}
.faq-component .faq-list {
  width: 80%;
  max-width: 900px;
}
.faq-component .faq-list .faq-item {
  background-color: rgba(255, 255, 255, 0.027);
  border-radius: 10px;
  padding: 0.5rem;
  margin-bottom: 20px;
  cursor: pointer;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}
.faq-component .faq-list .faq-item:hover {
  box-shadow: 0 5px 15px rgba(255, 255, 255, 0.507);
  transform: translateY(-5px);
}
.faq-component .faq-list .faq-item .faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 1.1s ease;
}
.faq-component .faq-list .faq-item .faq-answer.open {
  max-height: 1000px; /* Dovoljno velika vrijednost da sadržaj stane */
  transition: max-height 1.1s ease;
}
.faq-component .faq-list .faq-item .faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.faq-component .faq-list .faq-item .faq-question h3 {
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.747);
  margin-bottom: 10px;
  letter-spacing: 1px;
  font-family: "roboto mono", sans-serif;
  font-weight: 200;
}
.faq-component .faq-list .faq-item .faq-question .arrow-icon {
  transition: transform 0.3s ease;
  font-size: 2rem;
  color: rgba(255, 255, 255, 0.747);
}
.faq-component .faq-list .faq-item .faq-question .arrow-icon.rotate {
  transform: rotate(180deg);
}
.faq-component .faq-list .faq-item p {
  font-size: 1rem;
  color: whitesmoke;
  margin-top: 10px;
  letter-spacing: 1.5px;
}

@media (max-width: 480px) {
  .faq-component {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
  }
  .faq-component .faq-component-title h2 {
    font-size: 1.5rem;
    margin-bottom: 60px;
    color: #ffffff;
    font-family: "roboto mono", sans-serif;
    font-weight: 400;
  }
}/*# sourceMappingURL=Questions.css.map */