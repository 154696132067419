@import "../utils/main.scss";

.why-choose-us {
  display: flex;
  flex-direction: column;

  align-items: center;
  padding: 50px 0;
  background-color: $background_color_div_light;

  min-height: 70vh;

  .title {
    display: flex;
    justify-content: center;
    height: 50%;
    margin-top: 40px;
    margin-bottom: 40px;
    h2 {
      font-size: 3rem;
      color: #333;
      margin-bottom: 40px;
    }
  }
  .benefits {
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 1200px;
    margin-top: 100px;
    overflow: hidden;

    .benefit {
      text-align: center;
      max-width: 300px;
      height: 400px;
      transition: transform 0.3s ease;
      padding: 30px;

      &:hover {
        transform: scale(1.1);
      }

      img {
        width: 80px;
        margin-bottom: 20px;
      }

      h3 {
        font-size: 1.5rem;
        margin-bottom: 20px;
      }

      p {
        font-size: 1rem;
        color: #666;
      }
    }
  }
}
// Small mobile (max-width: 480px)
@media (max-width: 480px) {
  .why-choose-us{
    display: flex;
    flex-direction: column;
    width: 100%;
   min-height: 100vh;
   align-items: center;
    .title{
      h2{
        @include fluid-font(2rem,3rem);
        margin-bottom: 0;
      }
    }
    .benefits{
      display: flex;
      flex-direction: column;
      margin-top: 0;
      padding: 2rem;
      align-content: center;
      .benefit{
        display: flex;
        flex-direction: column;
        height: auto;
        align-items: center;
      }
    }
  }
}

// Midle mobile (481px - 767px)
@media (min-width: 481px) and (max-width: 767px) {
  .why-choose-us{
    display: flex;
    flex-direction: column;
    width: 100%;
   min-height: 100vh;
   align-items: center;

    .title{
      h2{
        @include fluid-font(2rem,3rem);
      }
    }
    .benefits{
      display: flex;
      flex-direction: column;
      margin-top: 0;
      padding: 2rem;
      align-items: center;
      .benefit{
        display: flex;
        flex-direction: column;
        height: auto;
        align-items: center;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991px) {
}