@import "../utils/main.scss";

.contact-container {
  display: flex;
  justify-content: center;
  background: url("../../assets/images/fullsize/ContactFormBeckground.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
 width: 100%;
  height:100%;
  .contact-container-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    background-color: $background_color_div;
    padding: 2rem;
    .title {
      display: flex;
      justify-content: center;
      h2 {
        margin-top: 2rem;
        margin-bottom: 4rem;
        color: rgb(168,23,63);
        font-family: "roboto mono", sans-serif;
        font-size: 2.5rem;
        font-weight: 200;
      }
    }
    .contact-box {
      display: flex;
      flex-direction: row;
      margin: auto;
      max-width: 90%;
    justify-content: space-evenly;
     
     
      .contact-info-box {
        display: flex;
        flex-direction: column;
        width: 45%;
        padding: 2rem;
        border-left: 2px solid rgb(168,23,63);
        border-top: 2px solid rgb(168,23,63);
        border-radius: 10%;
        
        justify-content: space-evenly;
        align-items: center;
        .title-contact-info {
          display: flex;
          flex-direction: column;

          h4 {
            color: $text-color;
            font-size: 1.5rem;
            font-family: "roboto mono";
            font-weight: 600;
            letter-spacing: 3px;
            text-transform: uppercase;
          }
        }

        address {
          display: flex;
          flex-direction: column;
          font-style: normal;
          dl {
            margin-top: 20px;
            margin-bottom: 1rem;
            dt {
              font-weight: bold;
              margin-bottom: 0.5rem;
            }
            dd {
              display: flex;
              margin-left: 0;
              margin-bottom: 1rem;
              background-color: #3d3e3e;
              padding: 0.5rem;
              color: rgba(255, 255, 255, 0.781);
              justify-content: center;

              &:nth-child(1) {
                text-transform: capitalize;
              }
              &:hover {
                scale: 1.05;
              }
              a {
                text-decoration: none;
                color: rgba(255, 255, 255, 0.781);
              }
            }
          }
        }

        .social-icons {
          margin-top: 1.5rem;
          display: flex;
          gap: 1rem;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .title-social {
            h3 {
              color: $text-color;
              font-weight: 200;
              letter-spacing: 2px;
              font-size: 1.25rem;
              span {
                color: rgb(168,23,63);
              }
            }
          }
          .icons-social {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-evenly;
            align-items: center;
            margin-top: 20px;

            a {
              font-size: 4rem;
              color: #3b5998; // Facebook default color
              &.instagram {
                color: #e1306c; // Instagram default color
              }
              &.whatsapp {
                color: #25d366;
              }
              svg {
                font-size: 3rem;
                &:hover {
                  scale: 1.05;
                }
              }
            }
          }
        }
      }

      .contact-form {
        display: flex;
        flex-direction: column;
        width: 45%;
        padding: 1rem;
        background-color: #2c2a2a36;
        border-right: 2px solid rgb(168,23,63);
        border-bottom: 2px solid rgb(168,23,63);
        border-radius: 10%;     
           .title-contact {
          display: flex;
          flex-direction: column;
          a{
            text-decoration: none;
            color: white;
            font-weight: 700;
            font-style: normal;
            &:hover{
              text-decoration: underline;
            }
          }
          p {
            color: $text-color;
            padding: 15px;
            font-size: 1rem;
            font-weight: 200;
            letter-spacing: 1px;
            &:nth-child(2) {
              font-style: italic;
              font-weight: 400;
              font-size: 0.8rem;
            }
          }
        }

        .MuiTextField-root {
          margin-bottom: 1.5rem;
          width: 100%;
          background-color: rgba(100, 100, 100, 0);
          .MuiInputBase-input{
            color: white;
            background-color: rgba(245, 245, 245, 0);
          }
          .MuiInputBase-root{
            background-color: rgba(245, 245, 245, 0.452);

          }
        }
        .MuiButton-containedPrimary {
          margin-top: 1rem;
          margin-bottom: 100px;
        }
        .MuiInputBase-input-MuiOutlinedInput-input {
          color: white;
        }
        button{
          &:hover{
            transform: scale(1.02);
          }
        }

      }
    }
  }
}
@media (max-width: 480px) {
  .contact-container{
    
    .contact-container-box{
      padding: .5rem;
      .title{
        display: flex;
        flex-direction: column;
        h2{
          font-size: 1.7rem;
          line-height: 2;
        }
      }
      .contact-box{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 100%;
        margin: 0;
        padding-left: 1rem;
        padding-right: 1rem;
        align-items: center;
        .contact-info-box{
          display: flex;
          flex-direction: column;
          width: 100%;
          padding: 1rem 1rem;
          margin-bottom: 2rem;
          .title-contact-info{
            h4{
              font-size: 1.2rem;
              padding-top: 1rem;
              padding-bottom: 1rem;
              margin-bottom: 2rem;
            }
           
          }
          .contact-info{
            address{
              dl{
                dd{
                  font-size: 1rem;
                  border-radius: 2rem;
                  margin-bottom: 2rem;
                }
              }
            }
           
          }
          .social-icons{
            display: flex;
            flex-direction: column;
            .icons-social{
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              a  svg{
                font-size: 2.5rem;

              }
            }
          }
        }
        .contact-form{
          display: flex;
          flex-direction: column;
          width: 100%;
          background-color: transparent;
          margin-bottom: 2rem;
          .MuiButton-containedPrimary{
            background-color:rgb(168,23,63);
            color: whitesmoke;
          }
        }
      }
    }
  }
}

  // Midle mobile (481px - 767px)
  
  @media (min-width: 481px) and (max-width: 767px) {
    .contact-container{
    
      .contact-container-box{
        padding: .5rem;
        .title{
          display: flex;
          flex-direction: column;
          h2{
            font-size: 1.7rem;
            line-height: 2;
          }
        }
        .contact-box{
          display: flex;
          flex-direction: column;
          width: 100%;
         
          margin: 0;
          padding-left: 1rem;
          padding-right: 1rem;
          align-items: center;
          .contact-info-box{
            display: flex;
            flex-direction: column;
           width: 80%;
            padding: 1rem 1rem;
            margin-bottom: 2rem;
            .title-contact-info{
              h4{
                font-size: 1.2rem;
                padding-top: 1rem;
                padding-bottom: 1rem;
                margin-bottom: 2rem;
              }
             
            }
            .contact-info{
              address{
                dl{
                  dd{
                    font-size: 1rem;
                    border-radius: 2rem;
                    margin-bottom: 2rem;
                  }
                }
              }
             
            }
            .social-icons{
              display: flex;
              flex-direction: column;
              .icons-social{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                a  svg{
                  font-size: 2.5rem;
  
                }
              }
            }
          }
          .contact-form{
            display: flex;
            flex-direction: column;
            width: 80%;
            background-color: transparent;
            margin-bottom: 2rem;
            padding: 2rem 2rem;
            .MuiButton-containedPrimary{
              background-color: rgb(168,23,63);
              color: whitesmoke;
            }
          }
        }
      }
    }
  }
  // Tablet (768px - 991px)
  
  @media (min-width: 768px) and (max-width: 991px) {
    .contact-container{
    
      .contact-container-box{
        padding: .5rem;
        .title{
          display: flex;
          flex-direction: column;
          h2{
            font-size: 1.7rem;
            line-height: 2;
          }
        }
        .contact-box{
          display: flex;
          flex-direction: column;
          width: 100%;
         
          margin: 0;
          padding-left: 1rem;
          padding-right: 1rem;
          align-items: center;
          .contact-info-box{
            display: flex;
            flex-direction: column;
           width: 80%;
            padding: 1rem 1rem;
            margin-bottom: 2rem;
            .title-contact-info{
              h4{
                font-size: 1.2rem;
                padding-top: 1rem;
                padding-bottom: 1rem;
                margin-bottom: 2rem;
              }
             
            }
            .contact-info{
              address{
                dl{
                  dd{
                    font-size: 1rem;
                    border-radius: 2rem;
                    margin-bottom: 2rem;
                  }
                }
              }
             
            }
            .social-icons{
              display: flex;
              flex-direction: column;
              .icons-social{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                a  svg{
                  font-size: 2.5rem;
  
                }
              }
            }
          }
          .contact-form{
            display: flex;
            flex-direction: column;
            width: 80%;
            background-color: transparent;
            margin-bottom: 2rem;
            padding: 2rem 2rem;
            .MuiButton-containedPrimary{
              background-color: rgba(255, 0, 0, 0.747);
              color: whitesmoke;
            }
          }
        }
      }
    }
  }