@import "../utils/main.scss";

.privacy-Policy-Page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.938);

  .main-privacy-Policy-Page {
    display: flex;
    flex-direction: column;
    margin-top: 8rem;
    background-color: white;
    padding-bottom: 5rem;
    border-radius: 0 5rem 5rem 0;


    .main-privacy-Policy-Page-title {
      display: flex;
      flex-direction: column;
      margin: 06rem 9rem 0 9rem;
      h2 {
        font-family: "roboto mono", sans-serif;
        font-size: 2rem;
        font-weight: 400;
        color: rgb(95, 95, 95);
        margin: 0.5rem 0 1rem;
      }
      p {
        font-family: "roboto mono", sans-serif;
        font-size: 1.05rem;
        font-weight: 400;
        color: rgb(95, 95, 95);
        margin-bottom: 0.5rem;
        a {
          font-family: "roboto mono", sans-serif;
          font-size: 1.05rem;
          font-weight: 400;
          color: rgb(95, 95, 95);
          margin-bottom: 0.3rem;
          margin-top: 0.3rem;
          text-decoration: none;
          color: #c36;
          &:hover {
            color: rgb(95, 95, 95);
          }
        }
      }
      ul {
        transform: translateX(2rem);
        margin-bottom: 0.3rem;
        li {
          font-family: "roboto mono", sans-serif;
          font-size: 1.05rem;
          font-weight: 400;
          color: rgb(95, 95, 95);
          margin-bottom: 0.3rem;
          margin-top: 0.3rem;
          a {
            text-decoration: none;
            color: #c36;
            &:hover {
              color: rgb(95, 95, 95);
            }
          }
        }
      }
    }
  }
}
// Small mobile (max-width: 480px)

@media (max-width: 480px) {
.privacy-Policy-Page{
  display: flex;
  flex-direction: column;
  .main-privacy-Policy-Page{
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
   padding-right: 2rem;
    margin-top:auto;
    border-radius: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.486);
    .main-privacy-Policy-Page-title{
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0;
      margin-top: 6rem;
      h2{
        font-size: 1.5rem;
        color: white;
        margin-bottom: 3rem;
      }
      p, ul> li{
        font-size: 1rem;
        color: white;
        font-weight: 200;
        display: flex;
        flex-direction: column;
        text-wrap: wrap;
      }
      address{
        color: white;
      }
  
    }
  }
}
}

  // Midle mobile (481px - 767px)
  
  @media (min-width: 481px) and (max-width: 767px) {
    .privacy-Policy-Page{
      display: flex;
      flex-direction: column;
      .main-privacy-Policy-Page{
        display: flex;
        flex-direction: column;
        padding-left: 1rem;
        padding-right: 2rem;
        margin-top:auto;
        border-radius: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.486);
        .main-privacy-Policy-Page-title{
          display: flex;
          flex-direction: column;
          width: 100%;
          margin: 0;
          margin-top: 6rem;
          h2{
            font-size: 1.5rem;
            color: white;
            margin-bottom: 3rem;
          }
          p, ul> li{
            font-size: 1rem;
            color: white;
            font-weight: 200;
          }
          address{
            color: white;
          }
      
        }
      }
    }
  }
  //Tablet
  @media (min-width: 768px) and (max-width: 991px) {
  
  }
