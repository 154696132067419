@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Edu+AU+VIC+WA+NT+Guides:wght@400..700&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap");
.why-choose-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
  background-color: rgba(228, 223, 223, 0.9);
  min-height: 70vh;
}
.why-choose-us .title {
  display: flex;
  justify-content: center;
  height: 50%;
  margin-top: 40px;
  margin-bottom: 40px;
}
.why-choose-us .title h2 {
  font-size: 3rem;
  color: #333;
  margin-bottom: 40px;
}
.why-choose-us .benefits {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 1200px;
  margin-top: 100px;
  overflow: hidden;
}
.why-choose-us .benefits .benefit {
  text-align: center;
  max-width: 300px;
  height: 400px;
  transition: transform 0.3s ease;
  padding: 30px;
}
.why-choose-us .benefits .benefit:hover {
  transform: scale(1.1);
}
.why-choose-us .benefits .benefit img {
  width: 80px;
  margin-bottom: 20px;
}
.why-choose-us .benefits .benefit h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}
.why-choose-us .benefits .benefit p {
  font-size: 1rem;
  color: #666;
}

@media (max-width: 480px) {
  .why-choose-us {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    align-items: center;
  }
  .why-choose-us .title h2 {
    font-size: clamp(2rem, 1rem + 1vw, 3rem);
    margin-bottom: 0;
  }
  .why-choose-us .benefits {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    padding: 2rem;
    align-content: center;
  }
  .why-choose-us .benefits .benefit {
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: center;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .why-choose-us {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    align-items: center;
  }
  .why-choose-us .title h2 {
    font-size: clamp(2rem, 1rem + 1vw, 3rem);
  }
  .why-choose-us .benefits {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    padding: 2rem;
    align-items: center;
  }
  .why-choose-us .benefits .benefit {
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: center;
  }
}/*# sourceMappingURL=WhyChooseUs.css.map */