@import "../utils/main.scss";

.kitchen {
  display: flex;
  flex-direction: column;
  padding-left: 3rem;
  padding-right: 3rem;
  width: 100%;
  min-height: 110vh;
  justify-content: center;

  &:nth-child(even) {
    background-image: linear-gradient(120deg, #fdfbfb56 0%, #ebedee86 100%);
    h2,
    p {
      color: rgb(255, 255, 255); // Change h2 and p text color to black
    }
  }

  .kitchen-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
   height: 100%;

    .title-kitchen {
      padding-bottom: 03rem;
      color: rgb(221, 218, 218);

      h2 {
        margin: 0;
        font-size: 2rem;
        padding-bottom: 2rem;
      }

      p {
       // margin: 5px 0;
        font-size: 1.2rem;
        line-height: 1.5;
        font-weight: 400;
        span {
          font-style: italic;
         // margin-left: 2rem;
          font-weight: 200;
          font-family: 'roboto mono', sans-serif;
        }
        a{
          text-decoration: none;
          color: rgb(221, 218, 218);
          padding-left: 1rem;
          font-style: italic;
          // margin-left: 2rem;
           font-weight: 200;
           font-family: 'roboto mono', sans-serif;
           &:hover{
            text-decoration: underline;
           }

        }
        &:nth-child(4){
          font-style: italic;
          font-size: 1.2rem;
        }
      }
    }

    .gallery {
      display: flex;
      flex-direction: row;
      position: relative; // Dodano za strelice
      width: 100%;
      height: 100%;
      align-content: space-between;
      .large-image {
        display: flex;
        margin-right: 2rem;
        min-width: 500px;
        height: 600px;
      
        transition: transform 0.4s ease, opacity 0.4s ease;

        &:hover {
          transform: scale(1.05);
          opacity: 0.8;
        }

        a {
          display: flex;
          width: 100%;
          height: 100%;
        }

        img {
          display: block; 
          width: 100%;
          height: 100%;
          border-radius: 1rem;
          object-fit: cover; /* Održava proporcije i popunjava kontejner */
          object-position: center; /* Centriranje slike */
        }
      }

      .small-images {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: 600px; // Ostavi visinu 600px ako želiš da slike budu iste visine
        justify-content: start;
        align-content: space-evenly;
        position: relative;

        transition: transform 0.4s ease, opacity 0.4s ease;
      

      a {
        margin-left: 1rem;
        margin-bottom: .5rem;
        max-width: 120px; // Postavi fiksnu širinu za slike
        max-height: 120px; // Postavi visinu za slike
        &:hover {
          transform: scale(1.05);

          opacity: 0.8;
        }

        img {
          display: flex;
          width: 100%;
          height: 100%; // Postavi da slike ispunjavaju cijeli prostor
          object-fit: cover; // Održava proporcije slike
          border-radius: 5px;
        }
      }
    }}

    // Stilovi za strelice
    .arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 10;
    }

    .arrow--left {
      left: 10px;
    }

    .arrow--right {
      right: 10px;
    }

    .arrow svg {
      fill: white;
      width: 20px;
      height: 20px;
    }
  }
  .pswp__img {
    display: block;
    width: 100%; /* Širina slike 100% od kontejnera */
    height: auto; /* Visina automatski postavljena prema proporcijama slike */
    object-fit: contain;
    object-position: center; /* Slika ispunjava prostor bez izobličenja */
    border-radius: 5px; /* Opcionalno, za zaobljene ivice */
  }
}
// Small mobile (max-width: 480px)
@media (max-width: 480px) {
  .kitchen {
    padding-left: 1rem;
    padding-right: 1rem;
    height: auto;
    margin-top: 2rem;



    .kitchen-box {
      .title-kitchen {
        h2 {
          font-size: 1.5rem;
        }

        p {
          font-size: 1rem;
          padding-top: 2rem;
        }
      }

      .gallery {
        flex-direction: column;

        .large-image {
          min-width: 100%;
          height: 300px;
        }

        .small-images {
          a {
            max-width: 80px;
            max-height: 80px;
          }
        }
      }
    }
  }
}

// Middle mobile (481px - 767px)
@media (min-width: 481px) and (max-width: 767px) {
  .kitchen {
    padding-left: 2rem;
    padding-right: 2rem;
    height: auto;
    margin-top: 2rem;


    .kitchen-box {
      .title-kitchen {
        h2 {
          font-size: 1.8rem;
        }

        p {
          font-size: 1rem;
        }
      }

      .gallery {
        .large-image {
          min-width: 400px;
          height: 400px;
        }

        .small-images {
          a {
            max-width: 100px;
            max-height: 100px;
          }
        }
      }
    }
  }
}

// Tablet (768px - 991px)
@media (min-width: 768px) and (max-width: 991px) {
  .kitchen {
    padding-left: 1rem;
    padding-right: 1rem;
    height: auto;
    margin-top: 4rem;


    .kitchen-box {
      .title-kitchen {
        padding-top: 2rem;
        h2 {
          font-size: 2rem;
        }

        p {
          font-size: 1rem;
        }
      }

      .gallery {
        .large-image {
          min-width: 350px;
          height: 450px;
        }

        .small-images {
          a {
            max-width: 100px;
            max-height: 100px;
          }
        }
      }
    }
  }
}