@import "../utils/colors.scss";

@mixin hover-effect {
    &:hover {
      background-color:$text-color;
      color: black;
      border-radius: 10px;
      transition: 0.5s ease-in-out all; }
      
  }
  @mixin fluid-font($min-size, $max-size) {
    font-size: clamp(#{$min-size}, 1rem + 1vw, #{$max-size});
  }