@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Edu+AU+VIC+WA+NT+Guides:wght@400..700&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap");
.contact-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background-color: black;
}
.contact-page main {
  display: flex;
  flex-direction: column-reverse;
}
.contact-page main .title-contact-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 70vh;
}
.contact-page main .title-contact-page .title-box1 h2 {
  color: white;
  letter-spacing: 2.5px;
  font-family: "roboto mono", sans-serif;
  font-size: 2.1rem;
  font-weight: 400;
  line-height: 1.5;
}
.contact-page main .title-contact-page .title-box1 h1 {
  color: rgb(168, 23, 63);
  font-size: 1.6rem;
  font-weight: 400;
  font-family: "roboto mono", sans-serif;
}
.contact-page main .title-contact-page .title-box2 {
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  padding-left: 1rem;
}
.contact-page main .title-contact-page .title-box2 p {
  color: white;
  letter-spacing: 2px;
  line-height: 1.5;
}
.contact-page main .title-contact-page .title-box2 p span a {
  color: white;
  letter-spacing: 2px;
  line-height: 1.5;
  text-decoration: none;
}
.contact-page main .title-contact-page .title-box2 .button {
  margin-top: 2rem;
}
.contact-page main .title-contact-page .title-box2 .button button {
  background-color: rgb(168, 23, 63);
  height: 1.8rem;
  width: 9rem;
  transition: all 0.3s ease;
  border-radius: 6px;
}
.contact-page main .title-contact-page .title-box2 .button button:hover {
  color: black;
  transform: scale(1.05);
}
.contact-page main .title-contact-page .title-box2 .button button a {
  text-decoration: none;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
}
.contact-page main .title-contact-page .title-box2 .button button a:hover {
  color: black;
  transition: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

@media (max-width: 480px) {
  .contact-page main {
    margin-top: 2rem;
  }
  .contact-page main .title-contact-page .title-box1 h2 {
    font-size: 2rem;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .contact-page main {
    margin-top: 2rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .contact-page main {
    margin-top: 3rem;
  }
}/*# sourceMappingURL=ContactPage.css.map */