@import "../utils/main.scss";

.faq-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
    background-color: rgb(0, 0, 0);
    .faq-component-title{
    h2 {
      font-size: 2.5rem;
      margin-bottom: 60px;
      color: #ffffff;
      font-family: 'roboto mono',sans-serif;
      font-weight: 400;
      //text-transform: capitalize;
    }
  }
    .faq-list {
      width: 80%;
      max-width: 900px;
  
      .faq-item {
        background-color:rgba(255, 255, 255, 0.027);
        //border: 2px solid #ccc;
        border-radius: 10px;
        padding: .5rem;
        margin-bottom: 20px;
        cursor: pointer;
        transition: box-shadow 0.3s ease, transform 0.3s ease;
  
        &:hover {
          box-shadow: 0 5px 15px rgba(255, 255, 255, 0.507);
          transform: translateY(-5px);
        }
        .faq-answer {
          max-height: 0;
          overflow: hidden;
          transition: max-height 1.1s ease;
        }
        
        .faq-answer.open {
          max-height: 1000px; /* Dovoljno velika vrijednost da sadržaj stane */
          transition: max-height 1.1s ease;
        }
  
        .faq-question {
          display: flex;
          justify-content: space-between;
          align-items: center;
  
          h3 {
            font-size: 1.1rem;
            color: rgba(255, 255, 255, 0.747);
            margin-bottom: 10px;
            letter-spacing: 1px;
            font-family: 'roboto mono',sans-serif;
            font-weight: 200;
          }
  
          .arrow-icon {
            transition: transform 0.3s ease;
            font-size: 2rem;
            color: rgba(255, 255, 255, 0.747);
          }
  
          .arrow-icon.rotate {
            transform: rotate(180deg);
          }
        }
  
        p {
          font-size: 1rem;
          color: whitesmoke;
          margin-top: 10px;
          letter-spacing: 1.5px
        }
      }
    }
  }
  // Small mobile (max-width: 480px)
@media (max-width: 480px) {
.faq-component{
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  .faq-component-title{
    h2 {
      font-size: 1.5rem;
      margin-bottom: 60px;
      color: #ffffff;
      font-family: 'roboto mono',sans-serif;
      font-weight: 400;
      //text-transform: capitalize;
    }
  }
}

}

// Midle mobile (481px - 767px)

@media (min-width: 481px) and (max-width: 767px) {
}
// Tablet (768px - 991px)

@media (min-width: 768px) and (max-width: 991px) {
}