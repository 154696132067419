@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Edu+AU+VIC+WA+NT+Guides:wght@400..700&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap");
.gallery-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background: rgb(0, 0, 0);
  background: linear-gradient(178deg, rgb(0, 0, 0) 45%, rgb(66, 66, 66) 100%);
}
.gallery-page main {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 1rem;
  margin-bottom: 8rem;
  align-items: center;
}
.gallery-page main .main-container {
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  height: 100%;
  align-content: center;
}
.gallery-page main .main-container .title-section {
  display: flex;
  flex-direction: column;
  padding: 4rem;
}
.gallery-page main .main-container .title-section p {
  font-size: 2rem;
  color: rgb(168, 23, 63);
  font-family: "Caveat", sans-serif;
  text-align: center;
  font-weight: 600;
  letter-spacing: 2px;
  margin-top: 2rem;
  margin-bottom: 4rem;
}
.gallery-page main .main-container .article-about {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 600px;
  justify-content: space-between;
  margin-bottom: 8rem;
}
.gallery-page main .main-container .article-about:nth-child(even) {
  flex-direction: row-reverse;
}
.gallery-page main .main-container .article-about:nth-child(even) img {
  -webkit-clip-path: polygon(100% 0, 100% 0, 100% 100%, 0 68%, 0 15%);
          clip-path: polygon(100% 0, 100% 0, 100% 100%, 0 68%, 0 15%);
}
.gallery-page main .main-container .article-about .article-about-box-descriptions {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 3rem;
}
.gallery-page main .main-container .article-about .article-about-box-descriptions h2 {
  color: white;
  font-size: 2.2rem;
  font-family: "Roboto Mono", sans-serif;
  letter-spacing: 3px;
  font-weight: 200;
  margin-bottom: 5rem;
}
.gallery-page main .main-container .article-about .article-about-box-descriptions p {
  font-size: 1.3rem;
  color: white;
  letter-spacing: 2px;
  font-family: "Roboto Mono";
  line-height: 1.5;
  font-weight: 300;
}
.gallery-page main .main-container .article-about .article-about-box-descriptions a {
  text-decoration: none;
  margin-top: 2rem;
  border: 1 solid rgb(168, 23, 63);
  border-radius: 0.5rem;
  padding: 0.5rem;
  color: white;
  background-color: rgb(168, 23, 63);
  width: 10rem;
  text-align: center;
}
.gallery-page main .main-container .article-about .article-about-box {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  padding-left: 2rem;
  margin-bottom: 10rem;
  overflow: hidden;
}
.gallery-page main .main-container .article-about .article-about-box img {
  display: flex;
  width: 100%;
  height: 600px;
  background-attachment: fixed;
  -o-object-fit: cover;
     object-fit: cover;
}

@media (max-width: 480px) {
  .gallery-page main {
    margin-top: 3rem;
    margin-bottom: 4rem;
  }
  .gallery-page main .main-container .title-section {
    padding: 2rem;
  }
  .gallery-page main .main-container .title-section p {
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .gallery-page main .main-container .article-about {
    flex-direction: column;
    height: auto;
  }
  .gallery-page main .main-container .article-about:nth-child(even) {
    flex-direction: row-reverse;
    position: relative;
    height: 100%;
  }
  .gallery-page main .main-container .article-about .article-about-box-descriptions p {
    letter-spacing: normal;
  }
  .gallery-page main .main-container .article-about .article-about-box-descriptions,
  .gallery-page main .main-container .article-about .article-about-box {
    width: 100%;
    padding: 1rem;
    margin: 0;
  }
  .gallery-page main .main-container .article-about .article-about-box-descriptions img,
  .gallery-page main .main-container .article-about .article-about-box img {
    width: 100%;
  }
  .gallery-page main .main-container .article-about .article-about-box-descriptions img:nth-child(even),
  .gallery-page main .main-container .article-about .article-about-box img:nth-child(even) {
    display: flex;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: fill;
       object-fit: fill;
    -webkit-clip-path: none !important;
            clip-path: none !important;
  }
  .gallery-page main .main-container .article-about .article-about-box-descriptions h2,
  .gallery-page main .main-container .article-about .article-about-box h2 {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
  .gallery-page main .main-container .article-about .article-about-box-descriptions p,
  .gallery-page main .main-container .article-about .article-about-box p {
    font-size: 1rem;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .gallery-page main {
    margin-top: 3rem;
    margin-bottom: 6rem;
  }
  .gallery-page main .main-container .title-section {
    padding: 3rem;
  }
  .gallery-page main .main-container .title-section p {
    font-size: 1.8rem;
  }
  .gallery-page main .main-container .article-about {
    flex-direction: column;
    height: auto;
  }
  .gallery-page main .main-container .article-about:nth-child(even) {
    flex-direction: row-reverse;
    position: relative;
    height: 100%;
  }
  .gallery-page main .main-container .article-about .article-about-box-descriptions,
  .gallery-page main .main-container .article-about .article-about-box {
    width: 100%;
    padding: 1rem;
    margin: 0;
  }
  .gallery-page main .main-container .article-about .article-about-box-descriptions img,
  .gallery-page main .main-container .article-about .article-about-box img {
    width: 100%;
  }
  .gallery-page main .main-container .article-about .article-about-box-descriptions img:nth-child(even),
  .gallery-page main .main-container .article-about .article-about-box img:nth-child(even) {
    display: flex;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .gallery-page main .main-container .article-about .article-about-box-descriptions h2,
  .gallery-page main .main-container .article-about .article-about-box h2 {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
  .gallery-page main .main-container .article-about .article-about-box-descriptions p,
  .gallery-page main .main-container .article-about .article-about-box p {
    font-size: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .gallery-page main {
    margin-top: 1rem;
    margin-bottom: 7rem;
  }
  .gallery-page main .main-container .title-section {
    padding: 3.5rem;
  }
  .gallery-page main .main-container .title-section p {
    font-size: 1.9rem;
  }
  .gallery-page main .main-container .article-about .article-about-box-descriptions,
  .gallery-page main .main-container .article-about .article-about-box {
    width: 100%;
    padding: 2.5rem;
  }
  .gallery-page main .main-container .article-about .article-about-box-descriptions h2,
  .gallery-page main .main-container .article-about .article-about-box h2 {
    font-size: 2.1rem;
  }
  .gallery-page main .main-container .article-about .article-about-box-descriptions p,
  .gallery-page main .main-container .article-about .article-about-box p {
    font-size: 1.2rem;
  }
  .gallery-page main .main-container .article-about .article-about-box img {
    height: 500px;
  }
}/*# sourceMappingURL=galleryPage.css.map */