@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Edu+AU+VIC+WA+NT+Guides:wght@400..700&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap");
.kitchens-page {
  display: flex;
  flex-direction: column;
  background-color: black;
  min-height: 110vh;
  width: 100%;
}
.kitchens-page main {
  display: flex;
  flex-direction: column;
}
.kitchens-page main .lazyload-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 2rem;
}
.kitchens-page main .lazyload-wrapper:nth-child(even) {
  background-color: rgba(73, 71, 71, 0.659);
  position: relative; /* Potrebno za pozicioniranje pseudo-elementa */
}
.kitchens-page main .lazyload-wrapper:nth-child(even):before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100px; /* Visina sjene, možete prilagoditi */
  background: rgba(73, 71, 71, 0.6588235294);
  background: linear-gradient(180deg, black, rgb(48, 47, 47) 100%);
  z-index: 1; /* Osigurava da je pseudo-element iznad pozadine */
}/*# sourceMappingURL=KitchensPage.css.map */